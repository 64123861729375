.withTabbarBottom {
  bottom: env(safe-area-inset-bottom, 0px)
}

.withoutTabbarBottom {
  bottom: calc(48px + env(safe-area-inset-bottom, 0px))
}

.newAppVersionBackgroundImage {
  opacity: 0.25;
  position: absolute;
  width: 240px;
  height: 240px;
  right: -20px;
  top: -35px;
}
